import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  right: -2px;
  left: -2px;
  bottom: -50px;
  width: calc(100% + 4px);
  z-index: 20;

  @media only screen and (max-width: 800px) {
    bottom: -10px;
  }

  svg {
    transition: fill 0.4s ease-in;
    fill: var(--color-background);
  }
`;

export const Wave = () => {
  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fillOpacity={1}
          d="M0,160L40,165.3C80,171,160,181,240,197.3C320,213,400,235,480,213.3C560,192,640,128,720,106.7C800,85,880,107,960,112C1040,117,1120,107,1200,96C1280,85,1360,75,1400,69.3L1440,64L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
        ></path>
      </svg>
    </Container>
  );
};
