import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

type ProfileData = {
  email: string;
  company: string;
  title: string;
  phone: string;
  github: string;
  linkedin: string;
  twitter: string;
  image: {
    fluid: {
      src: string;
    };
  };
  shortBio: {
    childMarkdownRemark: {
      html: string;
    };
  };
};

type Data = {
  contentfulPerson: ProfileData;
};

export const AboutMe = () => {
  const data: Data = useStaticQuery(graphql`
    query {
      contentfulPerson(name: { eq: "Darren Labithiotis" }) {
        image {
          fluid {
            src
          }
        }
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  return (
    <div>
      <Title>About me</Title>
      <Container>
        <Contents>
          <div dangerouslySetInnerHTML={{ __html: data.contentfulPerson.shortBio.childMarkdownRemark.html }} />
        </Contents>
        <ImageContainer>
          <Image image={data.contentfulPerson.image.fluid.src} />
        </ImageContainer>
      </Container>
    </div>
  );
};

const Title = styled.h1`
  @media only screen and (max-width: 800px) {
    width: 100%;
    text-align: center;
  }
`;

const Image = styled.div<{ image: string }>`
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.2s ease-in;
  background: url(${({ image }) => image}) center center;
  background-size: cover;

  :before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 30, 0.5) 100%);
    transition: opacity 0.2s ease;
  }

  :hover {
    transform: scale(1.05);

    &::before {
      opacity: 1;
    }
  }
`;

const ImageContainer = styled.div`
  margin-left: 30px;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;

    $Image {
      margin: 10px;
    }
  }
`;

const Contents = styled.div`
  flex: 1;
`;
