import { graphql, useStaticQuery } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Gallery, Media } from './Gallery';

type QueryData = {
  allContentfulPortfolio: {
    distinct: string[];
    nodes: {
      title: string;
      category: string;
      media: {
        title: string;
        description: string;
        file: {
          contentType: string;
        };
        localFile: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
      }[];
    }[];
  };
};

export function Portfolio() {
  const categories = [
    { title: 'Websites', category: 'website' },
    { title: 'Apps', category: 'app' },
    { title: 'Games', category: 'game' },
    { title: 'Art', category: 'art' },
  ];
  const queryData: QueryData = useStaticQuery(graphql`
    query {
      allContentfulPortfolio(sort: { order: DESC, fields: date }) {
        nodes {
          title
          category
          date
          media {
            title
            description
            file {
              contentType
            }
            localFile {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  const media: Media[] = [];
  const [tab, setTab] = useState(categories[0].category);

  for (const node of queryData.allContentfulPortfolio.nodes) {
    for (const nodeMedia of node.media) {
      if (nodeMedia.localFile?.childImageSharp) {
        const newMedia: Media = {
          title: nodeMedia.title,
          category: node.category,
          source: nodeMedia.localFile.childImageSharp.fluid,
        };
        media.push(newMedia);
      }
    }
  }

  return (
    <Container>
      <h1>Portfolio</h1>
      <p>Below is some of my professional work spanning the last decade.</p>
      <Tabs>
        {categories.map(({ title, category }, index) => (
          <Tab key={index} active={category === tab} onClick={() => setTab(category)}>
            {title}
          </Tab>
        ))}
      </Tabs>
      <Gallery media={media.filter(({ category }) => category === tab)} />
    </Container>
  );
}

const Container = styled.div`
  margin-bottom: 60px;
`;

const Tabs = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
`;

const Tab = styled.li<{ active: boolean }>`
  position: relative;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  padding: 0 10px;
  cursor: pointer;
  color: var(--color-primary);

  @media only screen and (max-width: 1000px) {
    padding: 0;
  }

  :before {
    position: absolute;
    display: block;
    content: ' ';
    bottom: -5px;
    right: 0;
    height: 4px;
    transition: left 0.4s ease-in, width 0.4s ease-in;
    background-color: var(--color-primary);
    left: ${({ active }) => (active ? '0%' : '50%')};
    width: ${({ active }) => (active ? '100%' : '0%')};
  }

  :hover:before {
    left: 20%;
    width: 60%;
    color: var(--color-primary);
  }
`;
