import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

import { BlogList } from './BlogList';

type Data = {
  allContentfulBlogPost: {
    edges: {
      node: {
        slug: string;
        title: string;
        publishDate: string;
        description: {
          childMarkdownRemark: {
            html: string;
          };
        };
        body: {
          childMarkdownRemark: {
            wordCount: {
              words: number;
            };
          };
        };
      };
    }[];
  };
};

export function RecentBlogs() {
  const data: Data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishDate, order: DESC }, limit: 10) {
        edges {
          node {
            slug
            title
            publishDate
            description {
              childMarkdownRemark {
                html
              }
            }
            body {
              childMarkdownRemark {
                wordCount {
                  words
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div>
      <h1>Recent blogs</h1>
      <BlogList blogs={data.allContentfulBlogPost.edges.map(({ node }) => node)} />
      <Link to="/blog/">See all my blogs</Link>
    </div>
  );
}
