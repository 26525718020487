import Img, { FluidObject } from 'gatsby-image';
import React, { useState } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import styled, { createGlobalStyle } from 'styled-components';

export type Media = {
  title: string;
  category: string;
  source: FluidObject;
};

type Props = {
  media: Media[];
};

export function Gallery({ media }: Props) {
  const [showLightBox, setShowLightBox] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Container>
      <GlobalStyles />
      {media.map(({ title, source }, index) => (
        <MediaPreview
          key={index}
          onClick={() => {
            setShowLightBox(true);
            setSelectedIndex(index);
          }}
        >
          <MediaContainer turn={randomTurn()}>
            <ImageContainer>
              <Img fluid={source} style={{ width: 'auto', height: '130px' }} imgStyle={{ objectFit: 'contain' }} />
            </ImageContainer>
          </MediaContainer>
        </MediaPreview>
      ))}
      <ModalGateway>
        {showLightBox && (
          <Modal onClose={() => setShowLightBox(false)} allowFullscreen={false}>
            <Carousel
              currentIndex={selectedIndex}
              views={media.map((media) => ({ caption: media.title, source: media.source.src }))}
            />
          </Modal>
        )}
      </ModalGateway>
    </Container>
  );
}

const GlobalStyles = createGlobalStyle`
  .fullscreen {
    position: relative;
    z-index: 200;
  }
  
  .react-images__view-image {
    max-height: 80vh !important;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MediaPreview = styled.div`
  position: relative;
  width: 25%;
  height: 140px;
  cursor: pointer;
  display: flex;

  @media only screen and (max-width: 1000px) {
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  z-index: 5;
  background-color: black;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid black;
  transition: transform 0.4s cubic-bezier(0, 0.5, 0.7, 1), z-index 0.4s cubic-bezier(0, 0.5, 0.7, 1),
    box-shadow 0.4s cubic-bezier(0, 0.5, 0.7, 1);
`;

const MediaContainer = styled.div<{ turn: number }>`
  position: relative;
  margin: 5px;
  flex: 1;

  :hover {
    ${ImageContainer} {
      z-index: 10;
      transform: scale(1.1) rotateZ(${({ turn }) => turn}deg);
      box-shadow: 2px 6px 6px 0px rgba(0, 0, 0, 0.3);
    }
  }
`;

const randomTurn = () => {
  const value = 1 + Math.floor(Math.random() * 3);
  return Math.random() > 0.5 ? value * -1 : value;
};
