import React from 'react';
import styled from 'styled-components';

import Icon, { Icons } from '../icons/Icon';

export function Intro() {
  return (
    <Section>
      <Contents>
        <Banner>
          <Title>Welcome</Title>
          <SubTitle>I’m a Software Engineer</SubTitle>
          <SubTitleSmall>
            Working remotely from Austria <Heart />
          </SubTitleSmall>
        </Banner>
      </Contents>
    </Section>
  );
}

const Section = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const Contents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  z-index: 5;
`;

const Banner = styled.div`
  text-align: center;
  color: #fff;
  padding: 40px;
  margin: 0 20px;
  border: 5px solid #fff;
  transition: background-color 0.5s ease, box-shadow 0.2s ease;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

  :hover {
    background-color: rgba(0, 0, 0, 0.9);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }

  @media only screen and (max-width: 1000px) {
    padding: 40px 20px;
  }
`;

const Title = styled.h1`
  margin: 0 0 15px;
  color: white;
`;

const SubTitle = styled.h2`
  margin: 0 0 5px;
  color: white;
`;

const SubTitleSmall = styled.h2`
  margin: 0 0 5px;
  font-size: 14px;
  color: white;
`;

const Heart = styled(Icon).attrs({
  size: 12,
  color: '#e50836',
  icon: Icons.heart,
})`
  display: inline-block;
  animation: pulse 1.2s infinite cubic-bezier(0, 0.45, 0.55, 1);
  @keyframes pulse {
    0% {
      transform: scale(1) translateY(2px);
    }
    50% {
      transform: scale(1.2) translateY(0px);
    }
    100% {
      transform: scale(1) translateY(2px);
    }
  }
`;
