import { PageProps, PageRendererProps } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AboutMe } from '../components/AboutMe';
import { Container } from '../components/Container';
import { Intro } from '../components/Intro';
import { Layout } from '../components/Layout';
import { Portfolio } from '../components/Portfolio';
import { RecentBlogs } from '../components/RecentBlogs';
import { SEO } from '../components/SEO';
import { Wave } from '../components/Wave';
import { getImageData } from '../utils/getImageData';

const IndexPage = ({ path }: PageProps) => {
  const [scrolledPastIntro, setScrolledPastIntro] = useState(true);

  useEffect(() => {
    const handleScroll = () => setScrolledPastIntro(window.scrollY < window.innerHeight - 80);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolledPastIntro]);

  return (
    <Layout path={path}>
      <SEO title="Home" />
      <BackgroundImage
        fluid={getImageData('house')}
        style={{ position: 'relative', overflow: 'hidden', marginTop: '-80px' }}
      >
        <Intro />
        <Wave />
      </BackgroundImage>
      <Content>
        <Container>
          <AboutMe />
          <Spacer />
          <RecentBlogs />
          <Spacer />
          <Spacer />
          <Portfolio />
        </Container>
      </Content>
    </Layout>
  );
};

const Content = styled.div`
  position: relative;
  background-color: var(--color-background);
  transition: background-color 0.4s ease-in;
  z-index: 20;
`;
const Spacer = styled.div`
  height: 40px;
`;

export default IndexPage;
