import { graphql, useStaticQuery } from 'gatsby';

export function getImageData(image: string): any {
  const data = useStaticQuery(graphql`
    query {
      allFile {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const imageData = data.allFile.edges.find((edge: any) => edge.node.name === image);
  return imageData.node.childImageSharp.fluid;
}
